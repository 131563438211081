import tippy from "tippy.js";
("use strict");
/* Product Single Page -> Social share popover */
var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover-share"]'));
popoverTriggerList.map(function (popoverTriggerEl) {
  const popoverId = popoverTriggerEl.getAttribute("data-content-id");
  const popoverButtonId = popoverTriggerEl.getAttribute("id");
  if (popoverId) {
    const contentEl = document.getElementById(popoverId).innerHTML;
    tippy("#" + popoverButtonId, {
      theme: "rlr-custom-tippy",
      trigger: "click",
      allowHTML: true,
      interactive: true,
      content: contentEl,
      maxWidth: 375
    });
  }
});
