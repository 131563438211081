import { FSCheckout } from "freemius-checkout-js";

// instantiate
const fsCheckout = new FSCheckout({
  plugin_id: "11507",
  public_key: "pk_db84e7b85216108baf2a989db0b9c"
});

if (document.querySelector(".elementor-1174")) {
  // Starter Plan - Call the API
  document.querySelector("#fs-starter").addEventListener("click", (e) => {
    e.preventDefault();
    // call the open method
    fsCheckout.open({
      // plan
      plan_id: 19603,
      // billing cycles
      billing_cycle: "annual"
    });
  });

  // Professional Plan - Call the API
  document.querySelector("#fs-professional").addEventListener("click", (e) => {
    e.preventDefault();
    // call the open method
    fsCheckout.open({
      // plan
      plan_id: 19610,
      // billing cycles
      billing_cycle: "annual"
    });
  });

  // Enterprise Plan - Call the API
  document.querySelector("#fs-enterprise").addEventListener("click", (e) => {
    e.preventDefault();
    // call the open method
    fsCheckout.open({
      // plan
      plan_id: 19611,
      // billing cycles
      billing_cycle: "annual"
    });
  });

  // Starter Plan - Call the API
  document.querySelector("#fs-starter-lt").addEventListener("click", (e) => {
    e.preventDefault();
    // call the open method
    fsCheckout.open({
      // plan
      plan_id: 19603,
      // billing cycles
      billing_cycle: "lifetime"
    });
  });

  // Professional Plan - Call the API
  document.querySelector("#fs-professional-lt").addEventListener("click", (e) => {
    e.preventDefault();
    // call the open method
    fsCheckout.open({
      // plan
      plan_id: 19610,
      // billing cycles
      billing_cycle: "lifetime"
    });
  });

  // Enterprise Plan - Call the API
  document.querySelector("#fs-enterprise-lt").addEventListener("click", (e) => {
    e.preventDefault();
    // call the open method
    fsCheckout.open({
      // plan
      plan_id: 19611,
      // billing cycles
      billing_cycle: "lifetime"
    });
  });
}
